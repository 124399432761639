import React from 'react'
import styled from 'styled-components'
import ScrollAnimation from 'react-animate-on-scroll'
import fbIcon from '../images/fb.png'
import igIcon from '../images/ig.png'
import ytIcon from '../images/yt.png'
import Headline from './headline'

const Contacts = styled.div`
  font-size: 1.3rem;
  @media (min-width: 815px) {
    font-size: 1.5rem;
  }
`
const P = styled.p`
  margin: 0;
  padding: 0.5rem;
  @media (min-width: 815px) {
    font-size: 1.3rem;
  }
`

const SocialIcons = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-top: 0.5rem;
`

const SocialIconLink = styled.a`
  flex: 0 1 25%;
  max-width: 120px;
`
const SocialIcon = styled.img`
  width: 100%;
  max-width: 150px;
  height: auto;
  transition: all 1s ease-out;
  &:hover {
    border-radius: 100%;
  }
`
const Mail = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: white;
`
class About extends React.Component {
  state = {
    phone: '',
    mail: '',
  }

  componentDidMount() {
    this.setState({
      phone: '📱 +49 (0) 151 55211632',
      mail: 'info@twotimes13.de',
    })
  }

  render() {
    return (
      <>
        <Headline>Kontakt</Headline>
        <ScrollAnimation
          initiallyVisible={true}
          animateOnce
          animateIn="tada"
          delay={300}
        >
          <P>
            Interesse geweckt?
            <br />
            Dann nehmt Kontakt mit uns auf!
          </P>
        </ScrollAnimation>
        <Contacts>
          {this.state.phone}
          <br />
          <Mail href={this.state.mail ? `mailto:${this.state.mail}` : '#'}>
            📧 {this.state.mail}
          </Mail>
        </Contacts>
        <P>Oder schreibt uns auf den sozialen Medien</P>
        <SocialIcons>
          <SocialIconLink
            href="https://www.facebook.com/twotimes13"
            title="facebook"
            rel="external nofollow"
          >
            <SocialIcon src={fbIcon} alt="facebook link" loading="lazy" />
          </SocialIconLink>
          <SocialIconLink
            href="https://www.instagram.com/twotimes13_band"
            rel="external nofollow"
            title="instagram"
          >
            <SocialIcon src={igIcon} alt="instagram link" loading="lazy" />
          </SocialIconLink>
          <SocialIconLink
            href="https://www.youtube.com/channel/UCiFur9o3CPP4Wog5ywXLaSg"
            title="youtube"
            rel="external nofollow"
          >
            <SocialIcon src={ytIcon} alt="youtube link" loading="lazy" />
          </SocialIconLink>
        </SocialIcons>
        <P>
          Natürlich freuen wir uns auch immer über Likes, Kommentare und Follows
          😉
        </P>
      </>
    )
  }
}
export default About
