import React from 'react'
import styled from 'styled-components'
import Headline from './headline'
import quoteIcon from '../images/quote-right.svg'
import ScrollAnimation from 'react-animate-on-scroll'

const Centered = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`
const Author = styled.p`
  font-size: 0.6rem;
  @media (min-width: 815px) {
    font-size: 0.8rem;
  }
  margin: 0;
  padding: 0;
`
const P = styled.p`
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;
  max-width: 950px;
  font-size: 0.9rem;
  @media (min-width: 815px) {
    font-size: 1.1rem;
  }
`

const Box = styled.div`
  margin: 1rem 2rem;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
`

const QuoteIcon = styled.img`
  height: 50;
  color: white;
  margin-bottom: 1rem;
`

export default function Testimonials() {
  return (
    <>
      <Headline>Reviews</Headline>
      <Centered>
        <ScrollAnimation animateOnce animateIn="fadeInLeft">
          <Box>
            <QuoteIcon src={quoteIcon} height={30} />
            <P>
              „Two Times 13 wurde unsererseits für einen Auftritt als
              Livemusiker am Sonntagnachmittag im Zuge des verkaufsoffenen
              Sonntags bei der Ittlinger Kirchweih gebucht. Mit großer
              musikalischer Qualität und einem guten Mix gelang es dem Duo für
              sehr gute Stimmung in der Ortsmitte zu sorgen. Gleichzeitig war
              die Band flexibel genug, auch kurzfristig auf andere
              Programmpunkte an diesem Nachmittag zu reagieren und sich
              entsprechend anzupassen. Das Feedback der Gäste und Aussteller war
              durchweg positiv, wir können Two Times 13 nur wärmstens
              empfehlen.“
            </P>
            <Author>- Kai Kohlenberger, Bürgermeister Ittlingen</Author>
          </Box>
        </ScrollAnimation>
        <ScrollAnimation animateOnce animateIn="fadeInRight">
          <Box>
            <QuoteIcon src={quoteIcon} height={30} />
            <P>
              „Der Auftritt des Acoustic Duos entsprach voll und ganz unseren
              Erwartungen. Mit einem Mix aus modernen und klassischen Songs,
              gepaart mit individuellen Einflüssen wie z.B. Live-Looping, wusste
              das Duo eine gute Bühnenpräsenz herzustellen. Die Kommunikation
              und Buchung gingen einfach und schnell.“
            </P>
            <Author>- Jubiläumsausschuss 1250 Jahre Richen</Author>
          </Box>
        </ScrollAnimation>
        <P></P>
      </Centered>
    </>
  )
}
