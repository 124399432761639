import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  width: 60px;
  height: 45px;
  position: relative;
  transform: rotate(0deg) scale3d(0.7, 0.7, 1);
  transition: 0.2s ease-in-out;
  background: none;
  border: 0;
  outline: none;
  z-index: 10;
  cursor: pointer;
  &:hover {
    transform: rotate(0deg) scale3d(0.8, 0.8, 1);
    transition: 0.2s ease-in-out;
  }
`
const Span = styled.span`
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: white;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.3s ease-in-out 0.1s;
`

const Top = styled(Span)`
  top: 0px;
  transform-origin: left center;
  &.open {
    transform: rotate(45deg);
    top: -3px;
    left: 8px;
  }
`
const Center = styled(Span)`
  top: 18px;
  transform-origin: left center;
  &.open {
    width: 0%;
    opacity: 0;
  }
`
const Bottom = styled(Span)`
  top: 36px;
  transform-origin: left center;
  &.open {
    transform: rotate(-45deg);
    top: 39px;
    left: 8px;
  }
`

const Menu = ({ menuOpen, toggleMenu }) => (
  <Button onClick={toggleMenu}>
    <Top className={menuOpen && 'open'} />
    <Center className={menuOpen && 'open'} />
    <Bottom className={menuOpen && 'open'} />
  </Button>
)
export default Menu
