import styled, { keyframes } from 'styled-components'
import React from 'react'
import videoMp4 from '../video/live.mp4'
import poster from '../video/poster.jpg'
import logo from '../images/logo.svg'
import arrowDownImage from '../images/arrow-down.svg'
import { Link as ScrollLink } from 'react-scroll'

const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`
const WelcomeBox = styled.div`
  margin-top: 22vh;
  padding: 1.2rem;
  @media (min-width: 815px) {
    padding: 2rem;
  }
  background-color: rgba(0, 0, 0, 0.5);
  animation-delay: 2000ms;
  animation-duration: 2000ms;
`

const Title = styled.div`
  font-size: 1.2rem;
  @media (min-width: 321px) {
    font-size: 1.4rem;
  }
  @media (min-width: 815px) {
    font-size: 1.9rem;
  }
  margin-bottom: 0.5rem;
`

const EmojiTexts = styled.div`
  font-size: 0.75rem;
  margin: 0.2rem 0;
  @media (min-width: 321px) {
    font-size: 0.9rem;
  }
  @media (min-width: 815px) {
    font-size: 1.25rem;
    margin: 0.4rem 0;
  }
`

const StyledScrollLink = styled(ScrollLink)`
  position: absolute;
  bottom: 1rem;
  animation-delay: 3000ms;
  animation-duration: 2000ms;
`

const fadeMoveDown = keyframes`
  0%   { transform:translate(0,-20px); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { transform:translate(0,20px); opacity: 0; }
`

const ArrowDown = styled.img`
  height: 5rem;
  cursor: pointer;
  animation-iteration-count: 10;
  animation: ${fadeMoveDown} 2s ease-in-out infinite;
`

const Video = styled.video`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`

const Img = styled.img`
  height: 100px;
  display: block;
  margin: 0 auto 1rem;
`

const SplashScreen = () => (
  <Wrapper>
    <Video autoPlay playsInline muted loop poster={poster}>
      <source src={videoMp4} type="video/mp4" />
    </Video>
    <WelcomeBox className={`animated fadeIn`}>
      <Img src={logo} alt="Logo Two Times 13" />
      <Title>
        Akustik Duo aus dem
        <br />
        Raum Karlsruhe / Bretten <br />
      </Title>
      <EmojiTexts>
        <nobr>👰Hochzeiten</nobr> &nbsp;
        <nobr>💒Trauungen</nobr> &nbsp;
        <nobr>💃Privatfeiern</nobr> &nbsp;
        <nobr>🕺Firmenfeiern</nobr> &nbsp;
        <nobr>🍻Stadtfeste</nobr>
      </EmojiTexts>
      <EmojiTexts>
        Songs aus Rock, Pop und Blues einzigartig
        <br />
        interpretiert und akustisch arrangiert.
      </EmojiTexts>
      <EmojiTexts>#livelooping #zweistimmig #vonsoftbisfetzig</EmojiTexts>
      <EmojiTexts>We ❤️ to harmonize</EmojiTexts>
    </WelcomeBox>
    <StyledScrollLink
      to="about"
      smooth={true}
      offset={-80}
      className={`animated fadeIn`}
    >
      <ArrowDown src={arrowDownImage} alt="Pfeil nach unten" height="5rem" />
    </StyledScrollLink>
  </Wrapper>
)

export default SplashScreen
