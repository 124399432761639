import styled from 'styled-components'
import React from 'react'

const StyledDiv = styled.div`
  margin: 1rem 0;
  font-family: Lora, serif;
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
`
const Headline = ({ children, small }) => (
  <StyledDiv style={small && { fontSize: '1.5rem', margin: '0.25rem' }}>
    {children}
  </StyledDiv>
)
export default Headline
