import React from 'react'
import AboutHero from './AboutHero'
import liveMicha from '../images/live-m.jpg'
import liveSabrina from '../images/live-s.jpg'
import liveBoth from '../images/live-both.jpg'
import Headline from './headline'
import styled from 'styled-components'
import ScrollAnimation from 'react-animate-on-scroll'
import { LiteYouTubeEmbed } from 'react-lite-youtube-embed'

const About = ({ hasScrolled }) => {
  const sabrinaDescription = (
    <>
      Sabrina hat Musik im Blut. Schon als Kind konnte sie sich für Gesang sehr
      begeistern. Es gibt kaum einen Song, den sie nicht schon einmal irgendwie
      gesungen hat - auf ihre ganz eigene und wundervolle Art. Ihre Wurzeln hat
      sie im Bereich Soul und Blues. Außerdem schlägt ihr Herz leidenschaftlich
      für Rockmusik. Ihr ausgeprägtes Gefühl für Musik und dessen Rhythmus nutzt
      sie bei Two Times 13 geschickt, um mit diversen Percussion Instrumenten
      für die gewisse Prise Beat zu sorgen. Ihr Gesangs- und Musikwissen reicht
      so weit, dass sie dieses auch in Unterrichtsstunden an andere weitergibt.
    </>
  )
  const michaDescription = (
    <>
      Micha spielt seit seinem Teenageralter Gitarre. Er konnte schon früh sein
      Talent in verschiedenen Bands ausleben und dadurch zahlreiche Erfahrungen
      sammeln. Musikalisch hat er seine Wurzeln im Bereich Rock und Metal, aber
      auch schon immer eine Schwäche für akustische Arrangements. Sein Favel für
      das Singen konnte er im Laufe der Zeit mit großer Begeisterung für sich
      entdecken und ausbauen. Seine tiefe, rauchige Stimme ergänzt den Gesang
      von Sabrina perfekt. An der Gitarre zeichnen ihn vor allem sein
      dynamisches Spiel und seine Live-Looping-Performances aus.
    </>
  )
  const bandDescription = (
    <>
      Ob alte Klassiker von Toto, den Beatles, CCR oder aktuellere Songs. Ob
      Rock, Pop oder Blues - unser Repertoire ist sehr facettenreich. Ein
      besonderes Merkmal unserer Musik ist es bekannte Songs nicht nur
      nachzuspielen, sondern jeder einzelnen Nummer eine ganz persönliche Note
      zu verleihen. Beispielsweise mit außergewöhnlichen
      Live-Looping-Arrangements, die uns kurzzeitig wie eine 4-köpfige Band
      klingen lassen oder erfrischenden Medley-Zusammenstellungen. Beim
      musikalischen Zusammenspiel trifft die gefühlvoll-soulige Klangfarbe von
      Sabrina auf die rockige Stimme und den vielseitigen Gitarrensound von
      Micha. Die harmonische Verschmelzung des zweistimmigen Gesangs macht uns
      als Duo einzigartig.
    </>
  )

  const ScrollWrapper = styled(ScrollAnimation)`
    max-width: 1000px;
    margin: 0 auto 30px;
  `
  const Wrapper = styled.div`
    width: 100%;
    padding-top: 56.25%;
    position: relative;
  `

  const InnerWrapper = styled.div`
    position: absolute;
    border: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  `
  return (
    <>
      <ScrollAnimation animateOnce animateIn="fadeInUp">
        <Headline>Erste Eindrücke</Headline>
      </ScrollAnimation>
      <ScrollWrapper animateOnce animateIn="fadeInUp">
        <Wrapper>
          <InnerWrapper>
            <LiteYouTubeEmbed
              id="tQMq0rPJMrM"
              title="Videovorschaubild zum Demo Reel auf YouTube"
            />
          </InnerWrapper>
        </Wrapper>
      </ScrollWrapper>
      <ScrollAnimation animateOnce animateIn="fadeInUp">
        <Headline>Wer wir sind</Headline>
      </ScrollAnimation>
      <AboutHero
        description={bandDescription}
        headline={<Headline>Two Times 13</Headline>}
        image={liveBoth}
        imageAlt="Two Times 13 Live Bild"
        hasScrolled={hasScrolled}
      />
      <AboutHero
        description={sabrinaDescription}
        headline={<Headline>Sabrina</Headline>}
        image={liveSabrina}
        imageAlt="Sabrina Live Bild"
        hasScrolled={hasScrolled}
        right={true}
      />
      <AboutHero
        description={michaDescription}
        headline={<Headline>Micha</Headline>}
        image={liveMicha}
        hasScrolled={hasScrolled}
        imageAlt="Micha Live Bild"
      />
    </>
  )
}
export default About
