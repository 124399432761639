/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Header from './header'
import './layout.css'
import './animate.css'

const Footer = styled.footer`
  background: rgba(30, 30, 25, 1);
  margin: 0;
  padding: 0.25rem 1.25rem 1.25rem 1.25rem;
`
const StyledLink = styled(Link)`
  color: rgba(16, 141, 199, 1);
`
const StyledMain = styled.main`
  overflow-x: hidden;
`

const Layout = ({ children, location }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div>
          <Header
            location={location}
            siteTitle={data.site.siteMetadata.title}
          />
          <StyledMain>{children}</StyledMain>
          <Footer>
            <h1>Two Times 13 - Acoustic Duo</h1>
            <h2>Songs aus Rock, Pop und Blues originell interpretiert</h2>
            <h3>
              Die ideale Band für Hochzeiten, Trauungen, Privatfeieren,
              Stadtfeste
            </h3>
            <h3>Bretten, Karlsruhe und Umgebung</h3>
            <h3>#livelooping #zweistimmig #vonsoftbisfetzig</h3>
            <StyledLink to="/impressum" title="impressum">
              Impressum {'&'} Datenschutz
            </StyledLink>
            <div>©{new Date().getFullYear()} | Two Times 13 GbR</div>
          </Footer>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
