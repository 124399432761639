import React from 'react'
import { Element } from 'react-scroll'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import About from '../components/about'
import Contact from '../components/contact'
import Setlist from '../components/setlist'
import Media from '../components/media'
import SplashScreen from '../components/splashScreen'
import Testimonials from '../components/testimonials'
import SiteImage from '../images/live-both.jpg'

const StyledElement = styled(Element)`
  border-top: 0.3rem solid rgba(0, 0, 0, 0.8);
`

const ContentBody = styled.div`
  background: black;
  background: linear-gradient(
    105deg,
    rgba(239, 142, 56, 1) 0%,
    rgba(144, 143, 134, 1) 60%,
    rgba(16, 141, 199, 1) 100%
  );
  padding-bottom: 1.25rem;
`

const IndexPage = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO
        title="Two Times 13 - Akustik Duo. Hochzeitsband. Rock & Pop Covers."
        keywords={[
          'Akustik Band',
          'Akustisches Duo',
          'Hochzeitsband',
          'Trauung',
          'Karlsruhe',
          'Bretten',
          'Baden-Würtemberg',
          'Partyband',
          'Coverband',
          'Walking Act ',
        ]}
        image={SiteImage}
      />
      <SplashScreen />
      <ContentBody>
        <StyledElement id="about">
          <About />
        </StyledElement>
        <StyledElement id="media">
          <Media />
        </StyledElement>
        <StyledElement id="setlist">
          <Setlist />
        </StyledElement>
        <StyledElement id="testimonials">
          <Testimonials />
        </StyledElement>
        <StyledElement id="contact">
          <Contact />
        </StyledElement>
      </ContentBody>
    </Layout>
  )
}

export default IndexPage
