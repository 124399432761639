import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import MenuButton from './MenuButton'
import thirteen from '../images/13.svg'
import { useScrollYPosition } from 'react-use-scroll-position'
import { Link as ScrollLink } from 'react-scroll'

const animationTime = 0.5

const StyledHeader = styled.header`
  font-family: Lora, serif;
  width: 100vw;
  position: fixed;
  top: 0;
  transition: all 0.3s ease-out;
  z-index: 999;
  animation-delay: 1000ms;
  animation-duration: 2000ms;
`

const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0.25rem 1rem;
`

const TopNavi = styled.nav`
  display: none;
  @media (min-width: 815px) {
    display: flex;
  }
`

const OverlayNavi = styled.nav``

const Logo = styled.img`
  height: 2.5rem;
  margin: 0.5rem 0;
  z-index: 10;
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const OverlayMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background-color: black;
  transition: opacity ${animationTime}s ease-out;
  opacity: 0;
  overflow: hidden;
  &.menu-open {
    opacity: 1;
  }
  a {
    font-size: 1.8rem;
  }
`

const StyledScrollLink = styled(ScrollLink)`
  display: flex;
  align-items: center;
  font-size: 1.15rem;
  @media (min-width: 1000px) {
    font-size: 1.5rem;
  }

  color: white;
  margin: 0 0.5rem;
  padding: 1rem 1rem;
  :hover {
    text-decoration: none;
    cursor: pointer;
    background: rgba(255, 255, 255, 1);
    color: black;
  }
`

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0 0.5rem;
  padding: 1rem 1rem;
  font-size: 1.2rem;
  color: white;
  :hover {
    text-decoration: none;
    cursor: pointer;
    background: rgba(255, 255, 255, 1);
    color: black;
  }
`

const Header = ({ location }) => {
  let bgColor
  const scrollY = useScrollYPosition()
  const [menuOpen, setMenuOpen] = useState(false)
  const [hiddenOverlay, setHiddenOverlay] = useState(false)

  useEffect(() => {
    if (!menuOpen) {
      setTimeout(() => {
        setHiddenOverlay(true)
      }, animationTime * 1000)
    } else {
      setHiddenOverlay(false)
    }
  }, [menuOpen])

  scrollY > 60 && (bgColor = 'rgba(0,0,0,0.8)')

  const menuLinksArray = [
    { id: 'about', title: 'Über uns' },
    { id: 'media', title: 'Hören & Sehen' },
    { id: 'setlist', title: 'Repertoire' },
    { id: 'testimonials', title: 'Reviews' },
    { id: 'contact', title: 'Kontakt' },
  ]
  const menuLinks = () => {
    if (location.pathname !== '/') {
      return (
        <BackLink to="/" title="home">
          Zurück zur Hauptseite
        </BackLink>
      )
    } else {
      return menuLinksArray.map(item => (
        <StyledScrollLink
          to={item.id}
          smooth={true}
          offset={-80}
          onClick={() => {
            setMenuOpen(false)
          }}
          key={item.id}
        >
          {item.title}
        </StyledScrollLink>
      ))
    }
  }

  return (
    <StyledHeader
      style={{ backgroundColor: bgColor }}
      className={`animated fadeIn`}
    >
      <FlexWrapper>
        <Link to="/" title="home">
          <LogoContainer title="Home" to="/">
            <Logo src={thirteen} alt="band logo" height="2.5rem" />
          </LogoContainer>
        </Link>
        <OverlayMenu
          className={`${menuOpen ? 'menu-open' : ''}`}
          style={hiddenOverlay ? { height: 0 } : { height: '100vh' }}
        >
          <OverlayNavi
            className={`animated ${menuOpen ? 'fadeInUp' : 'fadeOutDown'}`}
          >
            {menuLinks()}
          </OverlayNavi>
        </OverlayMenu>
        <TopNavi>{menuLinks()}</TopNavi>
        <MenuButton
          menuOpen={menuOpen}
          toggleMenu={() => setMenuOpen(!menuOpen)}
        />
      </FlexWrapper>
    </StyledHeader>
  )
}

export default Header
