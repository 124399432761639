import React from 'react'
import styled from 'styled-components'
import ScrollAnimation from 'react-animate-on-scroll'
import { LiteYouTubeEmbed } from 'react-lite-youtube-embed'
import Headline from '../components/headline'

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const InnerWrapper = styled.div`
  display: flex;
  max-width: 600px;
  @media (min-width: 815px) {
    max-width: 1200px;
  }
  margin: 0 auto 1.5rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  > * {
    margin-bottom: 0.5rem;
  }
`

const getYoutubeIFrame = id => (
  <LiteYouTubeEmbed
    wrapperClass="yt-lite yt-lite-media"
    id={id}
    title="Vorschaubild für ein YouTube Video"
    poster="mqdefault"
  />
)
const Media = () => {
  return (
    <OuterWrapper>
      <Headline>Hören &amp; Sehen</Headline>
      <InnerWrapper>
        <ScrollAnimation animateOnce animateIn="fadeInUp" delay={300}>
          {getYoutubeIFrame('J0ChmYcVHzU')}
        </ScrollAnimation>
        <ScrollAnimation animateOnce animateIn="fadeInUp" delay={600}>
          {getYoutubeIFrame('tpKp1Sv4awE')}
        </ScrollAnimation>
        <ScrollAnimation animateOnce animateIn="fadeInUp" delay={900}>
          {getYoutubeIFrame('UzRoaJzmOpE')}
        </ScrollAnimation>
        <ScrollAnimation animateOnce animateIn="fadeInUp" delay={900}>
          {getYoutubeIFrame('Y-85aMWsfno')}
        </ScrollAnimation>
        <ScrollAnimation animateOnce animateIn="fadeInUp" delay={300}>
          {getYoutubeIFrame('lVeN-gwaJVQ')}
        </ScrollAnimation>
        <ScrollAnimation animateOnce animateIn="fadeInUp" delay={600}>
          {getYoutubeIFrame('opq3I_GdG2U')}
        </ScrollAnimation>
      </InnerWrapper>
    </OuterWrapper>
  )
}
export default Media
