import React from 'react'
import styled from 'styled-components'
import ScrollAnimation from 'react-animate-on-scroll'

const AboutBlock = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 815px) {
    flex-direction: row;
  }
`

const LiveImg = styled.img`
  width: 100%;
  @supports (object-fit: cover) {
    @media (min-width: 815px) {
      object-fit: cover;
      object-position: 50% 20%;
      height: 530px;
    }
  }
`

const AboutHero = ({ description, image, imageAlt, headline, right }) => {
  const AboutImage = styled.div`
    flex: 0 0 50%;
    line-height: 1;
    @media (min-width: 815px) {
      order: ${({ right }) => (right ? 1 : 0)};
    }
  `
  const AboutTitle = styled.div`
    text-align: 'left';
    @media (min-width: 815px) {
      text-align: ${({ right }) => (right ? 'left' : 'right')};
    }
  `
  const AboutDescription = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1.5rem 2rem;
    flex: 0 0 50%;
    text-align: left;
    @media (min-width: 815px) {
      text-align: ${({ right }) => (right ? 'left' : 'right')};
      ${props => props.inputColor}
    }
  `

  return (
    <>
      <AboutBlock>
        <AboutImage right={right}>
          <ScrollAnimation
            animateOnce
            animateIn={right ? 'bounceInRight' : 'bounceInLeft'}
          >
            <LiveImg src={image} alt={imageAlt} loading="lazy" />
          </ScrollAnimation>
        </AboutImage>
        <AboutDescription right={right}>
          <div style={{ width: '100%' }}>
            <ScrollAnimation animateOnce animateIn="fadeInUp">
              <AboutTitle right={right}>{headline}</AboutTitle>
            </ScrollAnimation>
          </div>
          <ScrollAnimation animateOnce animateIn="fadeInUp" delay={300}>
            <div>{description}</div>
          </ScrollAnimation>
        </AboutDescription>
      </AboutBlock>
    </>
  )
}
export default AboutHero
