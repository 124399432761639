import styled from 'styled-components'
import React, { useState } from 'react'
import ArrowDown from '../images/arrow-down.svg'
import Headline from '../components/headline'
import ScrollAnimation from 'react-animate-on-scroll'

const StyledDiv = styled.div`
  display: inline-flex;
  justify-content: stretch;
  align-items: stretch;
  font-size: 1.15rem;
  flex-direction: column;
  overflow: hidden;
  height: auto;
  max-height: 125rem;
  &.collapsed {
    max-height: 34rem;
  }
  transition: max-height 0.7s ease-out;
  @media (min-width: 815px) {
    flex-direction: row;
    max-height: 9999px;
    &.collapsed {
      max-height: 9999px;
    }
    margin-bottom: 0px;
  }
`
const StyledUl = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 0px;
  font-size: 0.85rem;
  @media (min-width: 815px) {
    margin: 0 5px;
    font-size: 1rem;
  }
`
const ShowMore = styled.span`
  @media (min-width: 815px) {
    display: none;
  }
`

const CollapseButton = styled.button`
  background: none;
  border: none;
  display: block;
  width: 4.5rem;
  margin: 0.5rem auto;
  color: white;
  outline: 0;
  cursor: pointer;
`

const CollapseArrow = styled.span`
  img {
    transition: transform 0.3s;
    transition-delay: 0.7s;
    transform: rotate(180deg);
  }
  &.collapsed {
    img {
      transform: rotate(0deg);
    }
  }
  :focus {
    outline: none;
  }
  @media (min-width: 815px) {
    display: none;
  }
`

const songs = [
  '3 doors down - Here Without You',
  'Alannah Myles - Black Velvet',
  'Blink 182 - All the small things',
  'Bon Jovi - Always',
  'Bon Jovi - You give love a bad name',
  'Bruno Mars - Locked out of Heaven',
  "Bryan Adams & Mel C - When You're Gone",
  'Cat Stevens - Wild World',
  "Chubby Checker - Let's Twist Again",
  'Cindi Lauper - True Colors',
  'Creedence Clearwater Revival - Have You Ever Seen The Rain',
  'Creedence Clearwater Revival - Proud Mary',
  'Creedence Clearwater Revival - Bad Moon Rising',
  "Deep Blue Something - Breakfast at Tiffany's",
  'Duffy - Mercy',
  'Eric Clapton - Layla',
  'Eurythmics - Sweet Dreams',
  'Extreme - More Than Words',
  'Fleetwood Mac - Go Your Own Way',
  'Gary Moore - Walking by myself',
  'George Michael - Freedom 90',
  'Jason Mraz & Colbie Caillat - Lucky',
  'Jimmy Eat World - The Middle',
  'John Denver - Leaving on a Jetplane',
  'Jonas Brothers - Sucker',
  'Kansas - Dust in the Wind',
  'Katrina and the Waves - Walking on sunshine',
  'Kings of Leon - Sex on Fire',
  'KT Tunstall - Black Horse And The Cherry Tree',
  'Lady Antebellum - Need You Now',
  'Leonard Cohen - Halleluja',
  'Melissa Etheridge - Like the way I do',
  'Men At Work - Down Under',
  'Michael Jackson - Billie Jean',
  'Midnight Oil - Beds are burning',
  'Mike & the Mechanics - Another Cup of Coffee',
  'Nancy & Frank Sinatra - Something Stupid',
  'No Doubt - Just a Girl',
  "Pointer Sisters - I'm so excited",
  'Portugal The Man - Feel It Still',
  "Rembrandts - I'll be there for you (Friends)",
  'Ritchie Valens - La Bamba',
  'Savage Garden - Truly Madly Deeply',
  'Shocking Blue - Venus ',
  'Simon & Garfunkel - Mrs Robinson',
  'Soft Cell - Tainted Love',
  'Solomon Burke - Cry To Me ',
  'Staind - Tangled Up in You',
  'Stealers Wheel - Stuck in the middle with you',
  'Sting - Englishman in New York',
  'System of a Down - Lonley Day',
  "Tal Bachman - She's so high",
  'The Beatles - Eight Days A Week',
  'The Beatles - Help',
  'The Calling - Where ever you will go',
  'The Corrs - Breathless',
  'The Everly Brothers - Crying in the Rain',
  "The Monkees - I'm a believer",
  "The Proclaimers - I'm gonna be (500 miles)",
  'Times of Grace - The Forgotten One',
  "Tom Petty - Free Fallin'",
  'Toto - Africa',
]

const Setlist = () => {
  const [collapsed, setCollapsed] = useState(true)

  const leftColumn = songs.map(
    (song, i) => i < songs.length / 2 && <li key={`song_${i}`}>{song}</li>
  )
  const rightColumn = songs.map(
    (song, i) => i >= songs.length / 2 && <li key={`song_${i}`}>{song}</li>
  )

  const ScrollWrapper = styled(ScrollAnimation)`
    max-width: 1046px;
    margin: 0 auto 30px;
  `
  const Wrapper = styled.div`
    width: calc(100% - 2 * 2rem);
    height: 475px;
    position: relative;
    margin: 1rem 2rem;
  `

  const SpotifyIFrame = styled.iframe`
    position: absolute;
    border: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  `

  return (
    <>
      <Headline>REPERTOIRE</Headline>
      <ScrollWrapper animateOnce animateIn="fadeInUp">
        <Wrapper>
          <SpotifyIFrame
            loading="lazy"
            src="https://open.spotify.com/embed/playlist/52AkzFcon4Eed3SR1py4uT"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></SpotifyIFrame>
        </Wrapper>
      </ScrollWrapper>
    </>
  )
}
export default Setlist
